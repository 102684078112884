import React, { useState } from 'react'
import { useAsyncDebounce } from 'react-table';
import { useTranslation } from 'react-i18next';

const GlobalSearch = ({ globalFilter, setGlobalFilter }) => {

    const [ value, setValue ] = useState(globalFilter)
    const { t } = useTranslation();

    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)
  
    return (
        <input
          className='table-search'
          value={value || ''}
          placeholder={t('search.search')}
          onChange={e => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
        />
    )
}

  export default GlobalSearch