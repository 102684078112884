import '@fortawesome/fontawesome-free/css/all.min.css';
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import MapBox from '../components/mapbox/MapBox';
import '../css/admin.css';
import "../css/homepageContainer.css"; // Importera CSS-filen
import "../css/mapboxgl.css"; // Importera CSS-filen
import { fetchNumberOfRoofs, selectNumberOfRoofs } from '../redux/slices/landingpageSlice';


const MapboxRoofMap = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const numberOfRoofs = useSelector(selectNumberOfRoofs);

  useEffect(() => {
    try {
      dispatch(fetchNumberOfRoofs());
    } catch (error) {
      console.error("Dispatch Error:", error);
    }
  }, [dispatch]);

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    // Check if the user has visited before
    const hasVisited = localStorage.getItem("hasVisited");
    if (!hasVisited) {
      // Show the popup
      setShowModal(true);
      // Mark the user as visited
      localStorage.setItem("hasVisited", "true");
    }
  }, []);

  return (
      <div id="homepageContainer">
        <MapBox />
        <div id="homepageDashboard" className='homepageDashboard'>
          <div className="homepageDashboardElements">
            <p style={{fontSize: "20pt", fontWeight: "bold"}}>
              {t('text.coolifytitle')}
            </p>
            <p>
            {t('text.coolifybeskrivning')}
            </p>
          </div>
          <div className="homepageDashboardElementsLeft">
            <p style={{fontSize: "25pt", fontWeight: "bold"}}>{numberOfRoofs.data}</p>
            <p style={{fontSize: "18pt", fontWeight: "bold"}}>{t('text.kpititle')}</p>
            <p style={{fontSize: "15pt", fontWeight: "bold"}}>{t('text.kpisince')}</p>
            </div>
        </div>
        {showModal && (
          <div className="modal">
            <div className="modal-content">
              <h3 style={{color: "#1e73be"}}>{t('text.infotitle')}</h3>
              <ol style={{color: "#1e73be"}}>
                <li>{t('text.infotext1')}</li>
                <li>{t('text.infotext2')}</li>
                <li>{t('text.infotext3')}</li>
              </ol>
              <div className="modal-button-container">
                <button onClick={() => setShowModal(false)}>Stäng</button>
              </div>
            </div>
          </div>
        )}
        <div className="footer-arrow">
          <i onClick={() => setShowModal(true)} className="fa-solid fa-angle-up" style={{marginTop: "5vw", marginBottom: "-5vw"}}></i>
          <p style={{fontSize: "24px"}}>Om Global Coolify</p>
        </div>
      </div>
  );
};

export default MapboxRoofMap;
