import React from 'react'
import { useDispatch } from 'react-redux';
import FormModal from './FormModal';
import { deleteUser } from '../../redux/slices/usersSlice';
import { useSelector } from 'react-redux'
import { confirmationStyle } from './config';
import { useTranslation } from 'react-i18next';
import '../../css/addRoofForm.css';

const DeleteUserConfirmationModal = ({ open, onClose, userChanged }) => {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { user } = useSelector(state => state.auth); 

    const handleOnSubmit = async (event) => {
        event.preventDefault();
        try {
            const user_info = user
            await dispatch(deleteUser({"userID": userChanged.userID, "user_info":user_info})).unwrap();
            onClose();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <FormModal
            id='delete'
            isOpen={open}
            onCancel={() => onClose()}
            onSubmit={handleOnSubmit}
            contentstyle={confirmationStyle}
        >
            <h4>{t('confirmation.deleteUser')}</h4>
        </FormModal>
    )
}

export default DeleteUserConfirmationModal