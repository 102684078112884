import React, { useMemo, useState, useCallback, memo } from 'react'
import { useTable, useFilters, useGlobalFilter, usePagination, useFlexLayout, useExpanded } from 'react-table';
import GlobalSearch from './GlobalSearch';
import TablePagination from './TablePagination';
import { useTranslation } from 'react-i18next';
import filterIcon from  '../../assets/icons/filter.svg';
import ExportExcel from '../ExcelExport';
import Subrow from './Subrow';
import '../../css/table.css';


const TableRow = memo(({ row, isExpanded }) => {

    return (
        <tr>
            {row.cells.filter(cell => !cell.column.showInExpanded).map(cell => {
                console.log({cell})
                return (
                    <td className={isExpanded ? 'expanded' : ''} key={cell.id} {...cell.getCellProps({
                        style: {
                            minWidth: cell.column.minWidth,
                            width: cell.column.width,
                        },
                    })}>
                        {cell.render('Cell')}
                    </td>
                )
            })}
        </tr>
    )
})

const RoofTable = (props) => {

    const { t } = useTranslation(); 
    const data = useMemo(() => props.data || [], [props.data]);
    const columns = useMemo(() => props.columns, [props.columns]);
    const [ isFilterOpen, setIsFilterOpen ] = useState(false);
    const [editableRow, setEditableRow] = useState(null);
    const defaultColumn = useMemo(() => ({
        Filter: () => undefined,
        Cell: ({ value }) => <>{value?.length > 0 ? value : '--'}</>, 
        width: 150,
        minWidth: 20,
        maxWidth: 250,
    }), []); 

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        rows,
        prepareRow,
        state: { expanded, pageSize, pageIndex, ...state },
        preGlobalFilteredRows,
        setGlobalFilter,
        visibleColumns,
        ...paginationProps
    } = useTable({
        columns,
        data,
        defaultColumn,
        initialState: { pageIndex: 0, pageSize: 25},
        editableRow,
        autoResetFilters: false,
        setEditableRow,
    }, useFilters, useGlobalFilter, useFlexLayout, useExpanded, usePagination);

    const renderSearch = useCallback(() => (
        <GlobalSearch 
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
    ), [preGlobalFilteredRows, setGlobalFilter, state.globalFilter])
    
    const renderPagination = useCallback(() => (
        <TablePagination
            pageIndex={pageIndex}
            rows={rows}
            pageSize={pageSize}
            {...paginationProps}
        />
    ),[pageIndex, rows, pageSize, paginationProps]);

    console.log({columns});
    console.log({visibleColumns})
    console.log({headerGroups})
    return (
        <>
            <div className='table-menu'>
                {renderSearch()}
                <div className='buttons'>
                    <button className='filter-btn' onClick={() => setIsFilterOpen(!isFilterOpen)}>
                        <img src={filterIcon} alt='filter icon'/>
                        <span>{t('filter.filter')}</span>
                    </button>
                    <ExportExcel excelData={data} fileName={"Roof Data"} />
                </div>
            </div>
            <div className={`filters-section ${isFilterOpen ? 'expanded' : ''}`}  >
                {headerGroups.map(headerGroup => (
                    headerGroup.headers.map((column, index) => (
                        <React.Fragment key={index}>
                            {column.canFilter && (
                                <div key={column.id} className='filter-container'>
                                    <span>{t(column.Header)}</span>
                                    {column.render('Filter')}
                                </div>
                            )}
                        </React.Fragment>
                    ))
                ))}
            </div>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr  key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.filter(cell => !cell?.showInExpanded).map(column => (
                                <th key={column.id} {...column.getHeaderProps()} >
                                    {t(column.render('Header'))}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row)
                        row.getRowProps()
                        const{ isExpanded } = row;
                        return (
                            <React.Fragment key={row.id} >
                                <TableRow row={row} isExpanded={isExpanded} />
                                <Subrow row={row} isExpanded={isExpanded} />
                            </React.Fragment> 
                        )
                    })}
                </tbody>
            </table>
            <div className='table-footer'>
                {renderPagination()}
            </div>
        </>
    )
}

export default RoofTable;
