import { t } from 'i18next';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AddUserInputs } from '../../constants';
import { addUser, fetchUsers } from '../../redux/slices/usersSlice';
import FormInput from '../FormInput';
import { addUserStyle } from './config';
import FormModal from './FormModal'
import { useTranslation } from 'react-i18next';

const AddUserModal = ({ open, onClose }) => {

    const initialValues = {
      firstname: '',
      lastname: '',
      email: '',
      role: 'Employee',
    };
    const [ values, setValues ] = useState(initialValues);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { user } = useSelector(state => state.auth); 

    const handleonAdd = async (event) => {
        event.preventDefault();
        try {
          await dispatch(addUser({"new_user":values, "user_info": user})).unwrap();
          await dispatch(fetchUsers())
          setValues(initialValues);
          onClose();
        } catch (error) {
          
        }
    }

    const handleOnCancel = () => {
      onClose();
    }

    const handleOnChange = (event, input) => {
        setValues({ 
          ...values, 
          [input.name]: (event?.value || event?.target.value || '')
        });
    }
        
    return (
        <FormModal
          isOpen={open}
          onCancel={handleOnCancel}
          onSubmit={handleonAdd}
          contentstyle={addUserStyle}
          title={t('table_buttons.add_user')}
        >
          {AddUserInputs().map((input, i) => (
              <FormInput
                  key={i}
                  value={values[input.name]}
                  {...input}
                  onChange={(e) => handleOnChange(e, input)}
              />
          ))}
        </FormModal>
    )
}

export default AddUserModal
