import OptionFilter from "../table/OptionFilter"
import ExpandCell from "../table/ExpandCell";

const userColumns = [
    {
        Header: '', 
        id: 'expander', 
        Cell: ({ row }) => <ExpandCell row={row} />,
        width: 30,
        maxWidth: 50,
        minWidth: 30,
    },
    {
        Header: 'Name',
        accessor: 'user_name',
        Filter: OptionFilter,
        width: 150,
        maxWidth: 250,
        minWidth: 150,
    },
    {
        Header: 'Email',
        accessor: 'email',
        sortable: false,
        filterable: false,
        disableFilters: true,
        width: 200,
        maxWidth: 250,
        minWidth: 150,
    },
    {
        Header: 'User Role',
        accessor: 'role',
        Filter: OptionFilter,
        maxWidth: 150,
        minWidth: 100,
    }
];

export default userColumns;
