import React, { useState, memo } from 'react'
import { useSelector } from 'react-redux';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import DeleteUserConfirmationModal from '../modal/DeleteUserConfirmationModal';
import { useTranslation } from 'react-i18next';
import EditUserModal from '../modal/EditUserModal';

const UserSubrow = memo(({ row, isExpanded }) => {
    const { user } = useSelector(state => state.auth);
    const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const { t } = useTranslation();

    const handleOnDelete = () => {
        setIsDeleteConfirmationOpen(true);
    }

    const handleOnEdit = () => {
        setIsEditing(true);
    }

    return (
        <tr className={`user-sub-row ${isExpanded ? 'expanded' : ''}`} >
            <td className='expanded-user-data'>
                <div className='buttons-section'>
                    {user && Boolean(user.user_is_admin) && (
                        <button onClick={handleOnDelete}>
                            <DeleteIcon width={18} />
                        </button>
                    )}
                    {user && Boolean(user.user_is_admin) && (
                        <button onClick={handleOnEdit}>
                            <EditIcon width={18} />
                        </button>
                    )}
                </div>
                <EditUserModal
                    open={isEditing}
                    onClose={() => setIsEditing(false)}
                    editUser={row.original}
                />
                <DeleteUserConfirmationModal
                    open={isDeleteConfirmationOpen}
                    onClose={() => setIsDeleteConfirmationOpen(false)}
                    userChanged={row.original}
                />
            </td>
        </tr>
    )
})

export default UserSubrow;
