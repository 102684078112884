import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

const styles = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: (state.hasValue) ? '#049DD9' : 'rgb(204, 204, 204)',
      minHeight: '25px',
      height: '25px',
      fontSize: '.8rem',
      boxShadow: state.isFocused ? null : null,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '25px',
      padding: '0 6px',
    }),
    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: '.8rem',
      overflow: 'visible'
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '25px',
    }),
    menu: (provided) => ({...provided, zIndex: 9999})
};

const OptionFilter = ({ column }) => {
  const { filterValue, setFilter, preFilteredRows, id } = column;
  const { t } = useTranslation();

  const options = useMemo(() => {
    const optionsSet = new Set();
    preFilteredRows.forEach(row => {
      if(row.values[id] !== "" && row.values[id] !== null){
        optionsSet.add(row.values[id]?.trim());
      }
    })
    let sortedSet = [...optionsSet.values()].sort();
    let arr = [...sortedSet];
    arr.forEach((opt, index) => {
      arr[index] = {value: opt, label: opt};
    })
    return arr;
  }, [id, preFilteredRows])

  return (
    <Select
      value={filterValue?.label}
      options={options} 
      isClearable
      onChange={(option) => setFilter(option?.value)}
      styles={styles}
      placeholder={t('action.select')}
    />
  )
}

export default OptionFilter;
