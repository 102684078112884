import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next';
import ReactFlagsSelect from "react-flags-select";

const styles = {
    control: (style) => ({ ...style, display: 'flex', flexDirection: 'row', paddingLeft: '20px', alignItems: 'center', justifyContent: 'left', fontSize: '.8rem', minWidth: '170px', maxWidth: '170px' }),
    option: (style) => ({ ...style, display: 'flex', flexDirection: 'row', paddingLeft: '20px', alignItems: 'center', justifyContent: 'left', fontSize: '.8rem', minWidth: '170px', maxWidth: '170px' }),
};

const languages = [
  { value: 'GB', label: 'EN'},
  { value: 'SE', label: 'SV'},
  { value: 'UA', label: 'UK'},
  { value: 'ES', label: 'ES'},
];

const LanguageSelector = () => {
    
    const { i18n } = useTranslation();
    
    const currentLanguage = useMemo(() => {
        return languages.find(lng => lng?.label === i18n.resolvedLanguage.toUpperCase())?.value;
    },[i18n.resolvedLanguage]);

    const handleOnChange = (selected) => {
        let newLang = languages.find(lng => lng.value === selected);
        i18n.changeLanguage(newLang?.label.toLowerCase());
    }

    return (
        <ReactFlagsSelect
          selected={currentLanguage}
          onSelect={handleOnChange}
          countries={["GB", "SE", "UA", "ES"]}
          styles={styles}
          isSearchable={false}
          components={{ Option, Control: Option }}
          customLabels={{GB: 'EN', SE: "SV", ES: "ES", UA: "UA"}}
          placeholder="Select language"
        />
    )
}

export default LanguageSelector
