import React, { useState } from 'react'
import BaseSelect from 'react-select';
import FixRequiredSelect from './FixRequiredSelect';
import { useTranslation } from 'react-i18next';
import '../css/addRoofForm.css';

const FormInput = (props) => {
  const {label, onChange, onSelection, options, error, tooltip, inputRef, ...inputProps } = props;
  const [ isFocused, setIsFocused ] = useState(false);
  const { t } = useTranslation();

  const styles = {
    option: (style) => ({ ...style, display: 'flex', flexDirection: 'row', paddingLeft: '20px', alignItems: 'center', justifyContent: 'left', fontSize: '.8rem', width: 'calc(100% - (0.5rem * 2))'})
  };

  const handleOnChange = (e) => {
    setIsFocused(true);
    onChange(e);
  }
  
  const Select = props => (
    <FixRequiredSelect
      {...props}
      SelectComponent={BaseSelect}
      options={props.options || options}
    />
  );

  return (
    <div className='input-container'>
        <div className='input-header'>
            <label>{label}</label>
            {tooltip && tooltip && tooltip}
        </div>
        {inputProps.type === 'dropdown' && inputProps.name === 'evaluation' ? (
            <Select 
              placeholder={t("dropdown.select")}
              options={options} 
              onChange={handleOnChange} 
              styles={styles}
              value={options.find(opt => opt.value === inputProps.value)}
              //defaultValue={options.find(opt => opt.value === inputProps.value)}
              isClearable
            />
        ) : 
        inputProps.type === 'dropdown' ? (
          <Select 
            placeholder={t("dropdown.select")}
            options={options} 
            onChange={handleOnChange} 
            styles={styles}
            value={options.find(opt => opt.value === inputProps.value)}
            //defaultValue={options.find(opt => opt.value === inputProps.value)}
            isClearable
            required
          />
      )
        :  
        (
          <>
              <input 
                {...inputProps}
                onChange={handleOnChange} 
                focused={isFocused.toString()}
                ref={inputRef}
              />
              <span>{error}</span>
          </>
        )}
    </div>
  )
}

export default FormInput