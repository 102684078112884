import React, { Suspense } from 'react'
import { useTranslation } from 'react-i18next';

const RangeFilter = ({ column }) => {

    const { filterValue = [], setFilter } = column;
    const { t } = useTranslation();
    return (
        <Suspense fallback="...is loading">
            <input
              className='range from'
              placeholder={t('filter.from')}
              type='number'
              value={filterValue[0] || ''}
              onChange={e => {
                const val = e.target.value
                setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
              }}
            />
            <input
              placeholder={t('filter.to')}
              className='range to'
              type='number'
              value={filterValue[1] || ''}
              onChange={event => {
                const val = event.target.value
                setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])
              }}
            />
        </Suspense>
    )
}

export default RangeFilter;