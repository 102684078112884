import React from 'react'
import { Navigate, NavLink, Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import '../css/header.css';

const AdminOutlet = () => {
    const { t } = useTranslation();
    const { user } = useSelector(state => state.auth);

    if(!user || (user && !Boolean(user?.user_is_admin))){ 
        return <Navigate to='/roofs' replace /> 
    }
    
    return (
        <>
            <nav className='admin-nav'>
                <ul>
                    <li>
                        <NavLink end to='/admin'>{t('admin.statistics')}</NavLink>
                    </li>
                    <li>
                        <NavLink to='/admin/flagged'>{t('admin.flagged')}</NavLink>
                    </li>
                    <li>
                        <NavLink to='/admin/users'>{t('admin.users')}</NavLink>
                    </li>
                </ul>
            </nav>
            <Outlet />
        </>
    )
}

export default AdminOutlet
