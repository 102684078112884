import Modal from 'react-modal';

Modal.setAppElement('#root');

export const contentstyle = {
    top: '10vh',
    left: '25vw',
    right: '25vw',
    bottom: '15vh',
    padding: '0',  
    backgroundColor: '#e2f3fe',
};

export const overlayStyle = {
    backgroundColor: 'rgba(0,0,0, .45)',
}

export const confirmationStyle = {
    top: '30vh',
    left: '35vw',
    right: '35vw',
    bottom: '40vh',
    backgroundColor: '#e2f3fe',
};

export const addUserStyle = {
    top: '20vh',
    left: '30vw',
    right: '30vw',
    bottom: '30vh',
    padding: '0',
};
