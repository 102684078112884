import { Slide } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import WearTooltip from '../components/WearTooltip';
import VentilationTooltip from '../components/VentilationTooltip';
import RoofTypeTooltip from '../components/RoofTypeTooltip';

export const toastOptions = {
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    transition: Slide,
}

const onlyLettersPattern = '^[A-Öa-ö\u00C0-\u00ff\\s]*$';

export function AddRoofInputs() {
    const {t} = useTranslation();
    return [
    {
        name: 'company',
        label: t('Company *'),
        placeholder: t('Company'),
        type: 'text',
        required: true,
    },
    {
        name: 'image',
        label: 'Bild',
        type: 'file',
        required: false,
    },
    {
        name: 'adress',
        label: t('Address *'),
        placeholder: t('Address'),
        type: 'text',
        error: '',
        required: true,
    },
    {
        name: 'city',
        label: t('City *'),
        placeholder: t('City'),
        type: 'text',
        pattern: onlyLettersPattern,
        error: 'Only letters',
        required: true,
    },
    {
        name: 'country',
        label: t('Country *'),
        placeholder: t('Country'),
        type: 'text',
        pattern: onlyLettersPattern,
        error: 'Only letters',
        required: true,
    },
    {
        name: 'area',
        label: t('Area *'),
        placeholder: t('Area'),
        type: 'number',
        error: '',
        required: true,
    },
    {
        name: 'circumference',
        label: t('Perimeter *'),
        placeholder: t('Perimeter'),
        type: 'number',
        error: '',
        required: true,
    },
    {
        name: 'wear',
        label: t('Wear *'),
        placeholder: t('Wear'),
        type: 'dropdown',
        options: [
            { value: '1', label: t('1 (new)') },
            { value: '2', label: t('2 (medium)') },
            { value: '3', label: t('3 (old)') }
          ],
        error: '',
        required: true,
        tooltip: <WearTooltip />
    },
    {
        name: 'incline',
        label: t('Incline'),
        placeholder: t('Incline'),
        type: 'number',
        error: '',
        required: false,
    },
    {
        name: 'ventilation',
        label: t('Ventilation/Cooling *'),
        placeholder: t('Ventilation/Cooling'),
        type: 'dropdown',
        options: [
            { value: 'ventilation', label: t('Ventilation exists') },
            { value: 'cooling', label: t('Cooling exists') },
            { value: 'both', label: t('Ventilation & cooling') },
            { value: 'none', label: t('No ventilation or cooling')},
            { value: 'unsure', label: t('Unsure') }
          ],
        error: '',
        required: true,
        tooltip: <VentilationTooltip />,
    },
    {
        name: 'roof_type',
        label: t('Roofing Material *'),
        placeholder: t('Roofing Material'),
        type: 'dropdown',
        options: [
            { value: '1', label: t('sheetmetal') },
            { value: '2', label: t('roofingfelt') },
            { value: '3', label: t('concrete') },
            { value: '4', label: t('rubber') }
          ],
        error: '',
        required: true,
        tooltip: <RoofTypeTooltip />,
    },
    {
        name: 'evaluation',
        label: t('Evaluation'),
        placeholder: 'Evaluation',
        type: 'dropdown',
        options: [
            { value: '1', label: t('Completely correct') },
            { value: '2', label: t('Probably completely correct') },
            { value: '3', label: t('Incorrect location') },
            { value: '4', label: t('No location') },
            { value: '5', label: t('No location data') },
            { value: '6', label: t('Wrong city') },
          ],
        error: '',
        required: false,
    },
    {
        name: 'user_comment',
        label: t('Comment'),
        placeholder: t('Comment'),
        type: 'textarea',
        error: '',
        required: false,
    },
]};
export function AddUserInputs() {
    const {t} = useTranslation();
    return [
    {
        name: 'firstname',
        label: t('First name *'),
        placeholder: t('Enter first name'),
        type: 'text',
        pattern: onlyLettersPattern,
        error: 'Only letters',
        required: true,
    },
    {
        name: 'lastname',
        label: t('Last name *'),
        placeholder: t('Enter last name'),
        type: 'text',
        pattern: onlyLettersPattern,
        error: 'Only letters',
        required: true,
    },
    {
        name: 'email',
        label: t('Email *'),
        placeholder: t('Enter email'),
        type: 'email',
        required: true,
    },
    {
        name: 'role',
        label: t('User role *'),
        type: 'dropdown',
        options: [
            { value: 'Employee', label: t('Employee') },
            { value: 'Admin', label: t('Admin') },
          ],
        error: '',
        required: true,
    },
]}

export const adminColumns = [
    {
        Header: "User",
        accessor: 'userID',
    }
];

export const propertyOwnerInputs = [
    {
        name: 'organization',
        label: 'Organisation',
        placeholder: 'organisation',
        type: 'text',
        required: false,
    },
    {
        name: 'email',
        label: 'Email',
        placeholder: 'email',
        type: 'text',
        required: false,
    },
    {
        name: 'phone_number',
        label: 'Phone',
        placeholder: 'phone',
        type: 'text',
        required: false,
    },
]