import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { signOut } from '../redux/slices/authSlice';
import expandBtn from '../assets/icons/minimize.svg';
import LanguageSelector from '../components/LanguageSelector';

const DropdownMenu = () => {
    const { user, isLoggedIn } = useSelector(state => state.auth);
    const navigate = useNavigate();
    const [ open, setOpen ] = useState(false);
    const menuElement = useRef(null);
    const openButton = useRef(null);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleClickOutside = (event) => {
        if (menuElement.current && (!menuElement.current.contains(event.target) && !openButton.current.contains(event.target))) {
            setOpen(false)
        }
    }
    
    const handleSignOut = () => {
        isLoggedIn ? dispatch(signOut(user), navigate('/map')) : navigate('/login')
    }        

    useEffect(() => {
        setOpen(false);
    },[isLoggedIn])

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuElement]);
    return (
        <div className='dropdown'>
            <div className='header'>
                {isLoggedIn ?  (
                    <>
                        <img 
                            src={user?.picture} 
                            alt='user img' 
                            className='user-img' 
                            referrerPolicy="no-referrer"/>
                        <img 
                            src={expandBtn} 
                            alt='expand' 
                            className='expand-btn' 
                            ref={openButton} 
                            onClick={() => setOpen(!open)} 
                        />
                    </>
                ) : (
                    <button className='auth-btn' onClick={handleSignOut}>{isLoggedIn ? t('login.logout') : t('login.login')}</button>
                )}
            </div>
            {isLoggedIn && open &&  (
                <div className='menu' ref={menuElement}>
                    <span>{user?.user_name} {user?.lastname}</span>
                    <span>{user?.Email}</span>
                    <LanguageSelector />
                    <button className='auth-btn' onClick={handleSignOut}>{isLoggedIn ? t('login.logout') : t('login.login')}</button>
                </div>
            )}
        </div>
    )
}

export default DropdownMenu
