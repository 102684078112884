import React, { useState, memo } from 'react'
import { useSelector } from 'react-redux';
import { ReactComponent as FlagIcon } from '../../assets/icons/flag.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import DeleteConfirmationModal from '../modal/DeleteConfirmationModal';
import FlagConfirmationModal from '../modal/FlagConfirmationModal';
import DeFlagConfirmationModal from '../modal/DeFlagConfirmationModal';
import { useTranslation } from 'react-i18next';
import EditRoofModal from '../modal/EditRoofModal';
import ViewRoofImageModal from '../modal/view-roof-image/ViewRoofImageModal';

const Subrow = memo(({row, isExpanded}) => {
    const { user } = useSelector(state => state.auth);
    const [ isFlagConfirmationOpen, setIsFlagConfirmationOpen ] = useState(false);
    const [ isDeFlagConfirmationOpen, setIsDeFlagConfirmationOpen ] = useState(false);
    const [ isDeleteConfirmationOpen, setIsDeleteConfirmationOpen ] = useState(false);
    const [ isEditing, setIsEditing ] = useState(false);
    const [ isViewRoofModalOpen, setViewRoofModalOpen ] = useState(false);
    const { t } = useTranslation();
 
    if (!row || !row.original) return null;

    const handleOnFlag = () => {
        if(!row.original.is_flagged) {
            setIsFlagConfirmationOpen(true);
        }
        else if(row.original.is_flagged) {
            setIsDeFlagConfirmationOpen(true);
        }
    }
   
    const handleOnDelete = () => {
       setIsDeleteConfirmationOpen(true); 
    }

    const handleOnEdit = () => {
        setIsEditing(true);
    }
    
    return (
        <tr className={`sub-row ${isExpanded ? 'expanded' : ''}`} >
            <td className='expanded-data' >
                        <div className='roof-section'>
                            <span className='title'>{t('subrow.additional')}</span>
                            <div className='containers'>
                                <div className='container'>
                                    <span>{t('Wear')}</span>
                                    <span>{row.cells[9].render('Cell')}</span>
                                </div>
                                <div className='container'>
                                    <span>{t('Incline')}</span>
                                    <span>{row.cells[10].value}</span>
                                </div>
                                <div className='container'>
                                    <span>{t('Ventilation')}</span>
                                    <span>{row.cells[11].render('Cell')}</span>
                                </div>
                                <div className='container'>
                                    <span>{t('Evaluation')}</span>
                                    <span>{row.cells[12].render('Cell')}</span>
                                </div>
                                <div className='container'>
                            <span>{t('Created at')}</span>
                            <span>{row.cells[13].render('Cell')}</span>
                        </div>
                                <div className='container'>
                                    <span>{t('Comment')}</span>
                                    <span>{row.cells[14].render('Cell')}</span>
                                </div>
                            </div>
                        </div>
                        <div className='user-section'>
                            <span className='title'>{t('subrow.registered')}</span>
                            <div className='containers'>
                                <div className='container'>
                                    <span>{t('subrow.name')}</span>
                                    <span>{row.original.user?.user_name || '--'}</span>
                                </div>
                                <div className='container'>
                                    <span>{t('subrow.email')}</span>
                                    <span>{row.original.user?.Email || '--'}</span>
                                </div>
                            </div>
                        </div>
                        {user && Boolean(user.user_is_admin) && (
                        <div className='owner-section'>
                            <span className='title'>{t('subrow.owner')}</span>
                            <div className='containers'>
                                <div className='container'>
                                    <span>{t('subrow.organization')}</span>
                                    <span>{row.original.owner?.organization || '--'}</span>
                                </div>
                                <div className='container'>
                                    <span>{t('subrow.email')}</span>
                                    <span>{row.original.owner?.email || '--'}</span>
                                </div>
                                <div className='container'>
                                    <span>{t('subrow.phone')}</span>
                                    <span>{row.original.owner?.phone_number || '--'}</span>
                                </div>
                            </div>
                        </div>)}
                    <div className='buttons-section'>
                        {row.original.has_image && (
                            <button onClick={() => setViewRoofModalOpen(true)}>
                                Visa bild
                            </button>
                        )}
                        {user && !user?.user_is_admin && (
                            <button id='flag-btn' onClick={handleOnFlag}>
                                <FlagIcon width={18} fill={Boolean(row?.original?.is_flagged) ? '#F47642' : '#000000'} />
                            </button>
                        )}
                        {user && Boolean(user.user_is_admin) && (
                            <button onClick={handleOnDelete}>
                                <DeleteIcon width={18} />
                            </button>
                        )}
                        <button onClick={handleOnEdit}>
                            <EditIcon width={18} />
                        </button>
                    </div> 
                    {isEditing && <EditRoofModal 
                      open={true} 
                      onClose={() => setIsEditing(false)} 
                      roof={row.original} 
                    />}
                    
                    {isDeleteConfirmationOpen && <DeleteConfirmationModal 
                        open={true} 
                        onClose={() => setIsDeleteConfirmationOpen(false)} 
                        roof={row.original}
                    />}

                    {isFlagConfirmationOpen && <FlagConfirmationModal 
                        open={true} 
                        onClose={() => setIsFlagConfirmationOpen(false)}
                        roof={row.original} 
                    />}
                    {isDeFlagConfirmationOpen && <DeFlagConfirmationModal 
                        open={true} 
                        onClose={() => setIsDeFlagConfirmationOpen(false)}
                        roof={row.original} 
                    />}
                    {isViewRoofModalOpen && <ViewRoofImageModal 
                        open={true}
                        onClose={() => setViewRoofModalOpen(false)}
                        roof={row.original}
                    />}
            </td>
        </tr>
    )
})

export default Subrow;
