import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { toast } from "react-toastify";
import { getSession } from './authSlice';

export const fetchUsers = createAsyncThunk('users', async (_, { getState }) => {
    const response = await api.fetchUsers(getSession(getState).sessionId);
    return response;
});

export const addUser = createAsyncThunk('users/add', async (data, { getState }) => {
    const user = data['user_info']
    await api.addUser({"new_user": data["new_user"], "user_info": data["user_info"], ...getSession(getState)});
    return {user_name: `${user.firstname} ${user.lastname}`, email: user.email, role: user.role};
});

export const deleteUser = createAsyncThunk('users/delete', async (data, { getState }) => {
  const req = await api.deleteUser({data: {"userID": data["userID"], "user_info": data["user_info"], ...getSession(getState)}});
  if(req.response && req.response.status === 404){
    return req.response.data.message;
  }
  return data["userID"];
});

export const updateUser = createAsyncThunk('users/update', async (data, { getState, rejectWithValue }) => {
  const changedUser = data['changedUserData']
  const user = data['user_info']
  const req = await api.updateUser({data:{"changedUser": changedUser, "user_info": user, ...getSession(getState)}});
  if(req.response && req.response.status === 400){
    return rejectWithValue(req.response.data.message);
  }
  return user;
});

const initialStatus = {
    isIdle: true,
    isLoading: false,
    isSuccess: false,
    isError: false,
    error: ''
};

const initialState = {
    data: [],
    fetchStatus: initialStatus,
    updateStatus: initialStatus
};

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
          .addCase(fetchUsers.pending, (state) => {
            state.fetchStatus = {...initialStatus, isLoading: true};
          })
          .addCase(fetchUsers.fulfilled, (state, action) => {
            state.fetchStatus = {...initialStatus, isSuccess: true};
            state.data = action.payload;
          })
          .addCase(fetchUsers.rejected, (state, action) => {
            state.fetchStatus = {...initialStatus, isError: true, error: action.error.message};
          })
          .addCase(addUser.fulfilled, (state, action) => {
            toast.success('User added');
            state.data = [...state.data, action.payload];
          })
          .addCase(deleteUser.fulfilled, (state, action) => {
            let index = state.data.findIndex(user => user.userID === action.payload);
            state.data.splice(index, 1);
            toast.success('User deleted');            
          })
          .addCase(updateUser.pending, (state) => {
            state.updateStatus = {...initialState, isLoading: true, isSuccess: false};
          })
          .addCase(updateUser.fulfilled, (state, action) => {
            let index = state.data.findIndex(user => user.userID === action.payload.userID);
            state.data[index] = {...state.data[index], ...action.payload}
            state.updateStatus = {...initialState, isSuccess: true};
            toast.success('User updated...');
          })
          .addCase(updateUser.rejected, (state, action) => {
            state.updateStatus = {...initialState, isError: true, error: action.payload};
          })
    }
});

export default usersSlice.reducer;

export const getUsers = state => state.users.data;



