import OptionFilter from "./OptionFilter"
import RangeFilter from "./RangeFilter";
import ExpandCell from "./ExpandCell";
import ChangeToString from "./ChangeToString";
import ChangeWearToString from "./ChangeWearToString";

const tableColumns = [
    {
        Header: '', 
        id: 'expander', 
        Cell: ({ row }) => <ExpandCell row={row} />,
        width: 30,
        maxWidth: 50,
        minWidth: 30,
    },
    {
        Header: 'User',
        accessor: 'user.display_name',
        Filter: OptionFilter,
        filter: 'equals',
        width: 100,
        maxWidth: 100,
        minWidth: 50,
    },
    {
        Header: 'Company',
        accessor: 'company',
        disableFilters: true,
        width: 250,
        maxWidth: 250,
        minWidth: 150,
    },
    {
        Header: 'Address',
        accessor: 'adress',
        disableFilters: true,
        width: 250,
        maxWidth: 250,
        minWidth: 150,
    },
    {
        Header: 'City',
        accessor: 'city',
        Filter: OptionFilter,
        filter: 'equals',
        maxWidth: 150,
        minWidth: 100,
    },
    {
        Header: 'Country',
        accessor: 'country',
        Filter: OptionFilter,
        filter: 'equals',
        maxWidth: 150,
        minWidth: 100,
    },
    {
        Header: 'Perimeter',
        accessor: 'circumference',
        Filter: RangeFilter,
        filter: 'between',
        width: 70,
    },
    {
        Header: 'Area',
        accessor: 'area',
        Filter: RangeFilter,
        filter: 'between',
        width: 70,
    },
    {
        Header: 'Roof Type',
        accessor: 'roof_type',
        Cell: ({ row }) => {return ChangeToString(row)},
        Filter: OptionFilter,
        filter: 'equals',
        width: 100,
    },
    {
        Header: 'Geometry Type',
        accessor: 'geometry_type',
        Filter: OptionFilter,
        filter: 'equals',
        width: 100,
    },
    {
        Header: 'Wear',
        accessor: 'wear',
        Cell: ({ row }) => {return ChangeWearToString(row)},
        Filter: OptionFilter,
        filter: 'equals',
        showInExpanded: true,
    },
    {
        Header: 'Incline',
        accessor: 'incline',
        Filter: RangeFilter,
        filter: 'between',
        showInExpanded: true,
    },
    {
        Header: 'Ventilation',
        accessor: 'ventilation',
        Filter: OptionFilter,
        filter: 'equals',
        showInExpanded: true,
    },
    {
        Header: 'Evaluation',
        accessor: 'evaluation',
        Filter: OptionFilter,
        filter: 'equals',
        showInExpanded: true,
    },
    {
        Header: 'Created at',
        accessor: 'created_at',
        disableFilters: true,
        showInExpanded: true,
    },
    {
        Header: 'Comment',
        accessor: 'user_comment',
        disableFilters: true,
        showInExpanded: true,
    },
    {
        Header: 'Flagged',
        accessor: 'is_flagged',
        disableFilters: true,
    },
];

export default tableColumns;
