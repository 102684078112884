import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { toast } from "react-toastify";
import { getSession } from './authSlice';

const isRejectedAction = () => {}

export const fetchRoofs = createAsyncThunk('roofs', async (_, {getState}) => {
    const response = await api.fetchRoofs(getSession(getState).sessionId);
    if(typeof response !== 'object'){
      return [];
    }
    return response;
});

export const addRoof = createAsyncThunk('roofs/add', async (formData, { getState, rejectWithValue }) => {
    const sessionId = getSession(getState).sessionId;
    formData.append('sessionId', sessionId);

    await api.addRoof(formData);
});

export const deleteRoof = createAsyncThunk('roofs/delete', async (roofID, { getState }) => {
  const req = await api.deleteRoof(roofID, getSession(getState).sessionId);
  if(req.response && req.response.status === 404){
    return req.response.data.message;
  }
});

export const updateRoof = createAsyncThunk('roofs/update', async ({roofID, formData}, { getState, rejectWithValue }) => {
    const sessionId = getSession(getState).sessionId;
    formData.append('sessionId', sessionId);

    await api.updateRoof(roofID, formData);
});

export const flagRoof = createAsyncThunk('roofs/flag', async (data, { getState, rejectWithValue }) => {
  const roof = data['roof']
  const user = data['user_info']
  const req = await api.flagRoof({data:{"roof": roof, "user_info": user, ...getSession(getState)}});
  if(req.response && req.response.status === 400){
    return rejectWithValue(req.response.data.message);
  }
  return roof;
});

export const deFlagRoof = createAsyncThunk('roofs/deflag', async (data, { getState, rejectWithValue }) => {
  const roof = data['roof']
  const user = data['user_info']
  const req = await api.deFlagRoof({data:{"roof": roof, "user_info": user, ...getSession(getState)}});
  if(req.response && req.response.status === 400){
    return rejectWithValue(req.response.data.message);
  }
  return roof;
});

const initialStatus = {
  isIdle: true,
  isLoading: false,
  isSuccess: false,
  isError: false,
  error: ''
};

const initialState = {
    data: [],
    fetchStatus: initialStatus,
    addStatus: initialStatus
};

export const roofsSlice = createSlice({
    name: 'roofs',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
          .addCase(fetchRoofs.pending, (state) => {
            state.fetchStatus = {...initialState, isLoading: true, isIdle: false};
          })
          .addCase(fetchRoofs.fulfilled, (state, action) => {
            state.fetchStatus = {...initialState, isSuccess: true};
            state.data = action.payload;
          })
          .addCase(fetchRoofs.rejected, (state, action) => {
            state.fetchStatus = {...initialState, isError: true, error: action.error.message};
          })
          .addCase(addRoof.pending, (state) => {
            state.addStatus = {...initialState, isLoading: true};
          })
          .addCase(addRoof.fulfilled, (state, action) => {
            state.addStatus = {...initialState, isSuccess: true};
            toast.success('Roof uploaded...');
          })
          .addCase(addRoof.rejected, (state, action) => {
            state.addStatus = {...initialState, isError: true, error: action.payload};
          })
          .addCase(updateRoof.pending, (state) => {
            state.addStatus = {...initialState, isLoading: true};
          })
          .addCase(updateRoof.fulfilled, (state, action) => {
            state.addStatus = {...initialState, isSuccess: true};
            toast.success('Roof updated...');
          })
          .addCase(updateRoof.rejected, (state, action) => {
            state.addStatus = {...initialState, isError: true, error: action.payload};
          })
          .addCase(flagRoof.pending, (state) => {
            state.addStatus = {...initialState, isLoading: true};
          })
          .addCase(flagRoof.fulfilled, (state, action) => {
            let index = state.data.findIndex(roof => roof.roofID === action.payload.roofID);
            state.data[index] = {...state.data[index], is_flagged: 1}
            state.addStatus = {...initialState, isSuccess: true};
            toast.success('Roof flagged...');
          })
          .addCase(flagRoof.rejected, (state, action) => {
            state.addStatus = {...initialState, isError: true, error: action.payload};
          })
          .addCase(deFlagRoof.pending, (state) => {
            state.addStatus = {...initialState, isLoading: true};
          })
          .addCase(deFlagRoof.fulfilled, (state, action) => {
            let index = state.data.findIndex(roof => roof.roofID === action.payload.roofID);
            state.data[index] = {...state.data[index], is_flagged: 0}
            state.addStatus = {...initialState, isSuccess: true};
            toast.success('Roof deflagged...');
          })
          .addCase(deFlagRoof.rejected, (state, action) => {
            state.addStatus = {...initialState, isError: true, error: action.payload};
          })
          .addCase(deleteRoof.fulfilled, (state, action) => {
            let index = state.data.findIndex(roof => roof.roofID === action.payload);
            state.data.splice(index, 1);
            toast.success('Roof deleted');            
          })
          .addMatcher(isRejectedAction, (state, action) => {}) 
    }
});

export default roofsSlice.reducer;

export const getRoofs = state => state.roofs.data;

export const getRoofsByUserId = (userId) => {
  return state => state.roofs.data.filter(roof => roof.userID === userId);
}

export const getFlaggedRoofs = state => state.roofs.data.filter(roof => roof.is_flagged === 1);


