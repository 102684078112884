import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { getSession } from './authSlice';

export const fetchDashboardData = createAsyncThunk('dashboard', async (_, { getState }) => {
    const response = await api.fetchDashboardData(getSession(getState));
    return response.data;
})

const initialStatus = {
    isIdle: true,
    isLoading: false,
    isSuccess: false,
    isError: false,
    error: ''
};

const initialState = {
    data: [],
    status: initialStatus
};

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
          .addCase(fetchDashboardData.pending, (state) => {
            state.status = {...initialStatus, isLoading: true};
          })
          .addCase(fetchDashboardData.fulfilled, (state, action) => {
            state.status = {...initialStatus, isSuccess: true};
            state.data = action.payload;
          })
          .addCase(fetchDashboardData.rejected, (state, action) => {
            state.status = {...initialStatus, isError: true, error: action.error.message};
          })
    }
});

export default dashboardSlice.reducer;

export const getDashboardData = state => state.dashboard.data;



