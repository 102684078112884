import { useTranslation } from 'react-i18next';



const ChangeToString = input => {

    const { t } = useTranslation();
   
    let val = input.cells[8].value
    let label = ''
    if(val==1){
        label = t('sheetmetal')
    }else if(val==2){
        label = t('roofingfelt')
    }else if(val==3){
        label = t('concrete')
    }else if(val==4){
        label = t('rubber')
    } else {
        label = ''
    }
    return label
}

export default ChangeToString