import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Navigate, NavLink, Outlet } from 'react-router-dom'
import AddRoofModal from '../components/modal/AddRoofModal';
import '../css/roofs.css'

const RoofsOutlet = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const { isLoggedIn, user } = useSelector(state => state.auth);
    const { t } = useTranslation();

    if(!user) return <Navigate to='/map' replace /> 

    return (
        <>
            <div className='menu-row'>
                <nav>
                    <ul>
                        <li>
                            <NavLink end to='/roofs'>{t('header.allroofs')}</NavLink>
                        </li>
                        {isLoggedIn && 
                            (
                                <li>
                                    <NavLink to={`/roofs/${user?.userID}`}>{t('header.myroofs')}</NavLink>
                                </li>
                            )
                        }
                        
                    </ul>
                </nav>
                {isLoggedIn && 
                    <button
                        className='open-modal-btn'
                        onClick={() => setIsModalOpen(true)}
                    >
                        {t('header.addroof')}
                    </button>
                }
            </div>
            <AddRoofModal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
            />
            <Outlet />
        </>
    )
}

export default RoofsOutlet
