import { useTranslation } from 'react-i18next';



const ChangeWearToString = input => {

    const { t } = useTranslation();
   
    let val = input.cells[9].value
    let label = ''
    if(val==1){
        label =  t('1 (new)')
    }else if(val==2){
        label = t('2 (medium)')
    }else if(val==4){
        label = t('3 (old)')
    } else {
        label = ''
    }
    return label
}

export default ChangeWearToString