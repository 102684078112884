import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AddUserInputs } from '../../constants';
import { updateUser } from '../../redux/slices/usersSlice';
import FormInput from '../FormInput';
import FormModal from './FormModal'

const EditUserModal = ({ open, onClose, editUser }) => {

    const initialValues = {
        userID: editUser?.userID,
        firstname: editUser?.firstname,
        lastname: editUser?.lastname,
        email: editUser?.email,
        role: editUser?.role//.toLowerCase(),
      };

    const [ values, setValues ] = useState(initialValues);
    const { user } = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const getChangedData = (obj) => {
        for (const key in obj) {
            if (Object.hasOwnProperty.call(obj, key)) {
                if(!obj[key]) {
                    delete obj[key];
                }
            }
        }
        return obj; 
    }

    const handleOnSubmit = async (event) => {
        event.preventDefault();
        const changedData = getChangedData(values); 

        try {
            await dispatch(updateUser({"changedUserData": changedData, "user_info": user})).unwrap();
            setValues(initialValues);
            onClose();
        } catch (error) {
            console.log(error);
        }
    }

    const handleOnchange = (event, input) => {    
        setValues(prev => {
            return {...prev, [input.name]: (event?.value ||  event?.target?.value || '')}
        });
    };


    const handleOnCancel = () => {
        setValues(initialValues);
        onClose();
    }

    return (
        <FormModal
            isOpen={open}
            onCancel={handleOnCancel}
            onSubmit={handleOnSubmit}
            title={t('header.edituser')}
        >
            {AddUserInputs().map((input, i) => (
                <FormInput 
                    key={i} 
                    value={values[input.name]}
                    {...input} 
                    onChange={(e) => handleOnchange(e, input)}
                />
            ))}  
        </FormModal>
    )
}

export default EditUserModal
