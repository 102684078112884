import { configureStore } from '@reduxjs/toolkit';
import roofsReducer from './slices/roofsSlice';
import authReducer from './slices/authSlice';
import usersSlice from './slices/usersSlice';
import dashboardReducer from './slices/dashboardSlice';
import landingpageReducer from './slices/landingpageSlice';
export default configureStore({

    

    reducer: {
        roofs: roofsReducer,
        auth: authReducer,
        users: usersSlice,
        dashboard: dashboardReducer,
        landingpage:  landingpageReducer
    }
});