import React, { useState } from 'react'
import { AddRoofInputs } from '../../constants';
import FormInput from '../FormInput';
import { useDispatch, useSelector } from 'react-redux';
import { addRoof, fetchRoofs } from '../../redux/slices/roofsSlice';
import FormModal from './FormModal';
import { useTranslation } from 'react-i18next';
import '../../css/addRoofForm.css';

const AddRoofModal = ({ open, onClose }) => {

    const dispatch = useDispatch();
    const { isError, error } = useSelector(state => state.roofs.addStatus);
    const [ image, setImage ] = useState()

    const { t } = useTranslation();
    const initialValues = {
        company: '',
        adress: '',
        city: '',
        country: '',
        area: '',
        perimeter: '',
        wear: '',
        incline: '',
        circumference: '',
        ventilation: '',
        roof_type: '',
        evaluation: '',
        user_comment: '',
    };
    const [ values, setValues ] = useState(initialValues);

    const handleOnChange = (event, input) => {
        let value = 
            event?.value // from dropdown fields
            || event?.target?.value; // from normal fields

        setValues(prev => {
            return {...prev, [input.name]: value}
        });

        if (input.name === 'image' && event?.target?.files?.length) {
            setImage(event?.target?.files[0])
        }
    };

    const handleOnSubmit = async (event) => {
        
        event.preventDefault();

        try {
            onClose();

            const sendValues = values;
            setValues(initialValues);
            
            const formData = new FormData();

            Object.entries(sendValues).forEach(([k, v]) => {
                if (k === 'image') return;
                formData.append(k, v);
            })
            if (image) {
                formData.append('image', image)
            }

            await dispatch(addRoof(formData)).unwrap();
            await dispatch(fetchRoofs());

        } catch (error) {
            console.log(error);
        }
    };

    const handleOnCancel = () => {
        setValues(initialValues);
        onClose();
    }

    return (
        <FormModal
            isOpen={open}
            title={t('header.addroof')}
            onCancel={handleOnCancel}
            onSubmit={handleOnSubmit}
        >
            <span className={isError ? `error-message` : ''}>{error}</span>
            {AddRoofInputs().map((input, i) => (
                <FormInput 
                    key={i} 
                    value={values[input.name]}
                    {...input} 
                    onChange={(e) => handleOnChange(e, input)}
                />
            ))}
        </FormModal>
    )
}

export default AddRoofModal