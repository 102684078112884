import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AddRoofInputs, propertyOwnerInputs } from '../../constants';
import { fetchRoofs, updateRoof } from '../../redux/slices/roofsSlice';
import FormInput from '../FormInput';
import FormModal from './FormModal';

const EditRoofModal = ({ open, onClose, roof }) => {

    const initialValues = {
        roofID: roof?.roofID,
        company: roof?.company,
        adress: roof?.adress ,
        city: roof?.city,
        country: roof?.country,
        area: roof?.area,
        circumference: roof?.circumference,
        wear: roof?.wear,
        incline: roof?.incline,
        ventilation: roof?.ventilation,
        roof_type: roof?.roof_type,
        evaluation: roof?.evaluation,
        user_comment: roof?.user_comment,
        image: roof?.has_image ? '' : undefined
    };

    const initialOwnerValues = {
        organization: roof?.owner?.organization || '',
        email: roof.owner?.email || '',
        phone_number: roof.owner?.phone_number || '',
    }

    const [ values, setValues ] = useState(initialValues);
    const [ image, setImage ] = useState()
    const [ ownerValues, setOwnerValues ] = useState(initialOwnerValues);
    const { user } = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const getChangedData = (obj) => {
        for (const key in obj) {
            if (Object.hasOwnProperty.call(obj, key)) {
                if(!obj[key]) {
                    delete obj[key];
                }
            }
        }
        return obj; 
    }

    const handleOnSubmit = async (event) => {
        event.preventDefault();

        try {
            onClose();

            const sendValues = {...values, ...ownerValues}
            
            const formData = new FormData();

            Object.entries(sendValues).forEach(([k, v]) => {
                if (k === 'image') return;
                formData.append(k, v);
            })
            if (image) {
                formData.append('image', image)
            }

            await dispatch(updateRoof({roofID: roof.roofID, formData})).unwrap();
            await dispatch(fetchRoofs());
            setValues(initialValues);

        } catch (error) {
            console.log(error);
        }
    };

    const handleOnchange = (event, input) => {    
        let value = 
            event?.value // from dropdown fields
            || event?.target?.value; // from normal fields

        setValues(prev => {
            return {...prev, [input.name]: value}
        });

        if (input.name === 'image' && event?.target?.files?.length) {
            setImage(event?.target?.files[0])
        }
    };

    const handleOnOwnerchange = (event, input) => {
        setOwnerValues({ ...ownerValues, [input.name]: (event.value || event.target.value)})
    };

    const handleOnCancel = () => {
        setValues(initialValues);
        onClose();
    }

    return (
        <FormModal
            isOpen={open}
            onCancel={handleOnCancel}
            onSubmit={handleOnSubmit}
            title={t('header.editroof')}
        >
            {AddRoofInputs().map((input, i) => (
                <FormInput 
                    key={i} 
                    value={values[input.name]}
                    {...input} 
                    onChange={(e) => handleOnchange(e, input)}
                />
            ))}  
            <span className='owner-title'>{t('subrow.owner')}</span>
            {propertyOwnerInputs.map((input, i) => (
                <FormInput 
                    key={i}
                    value={ownerValues[input.name]}
                    {...input}
                    onChange={(e) => handleOnOwnerchange(e, input)}
                />
            ))}
        </FormModal>
    )
}

export default EditRoofModal
