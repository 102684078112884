import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { getSession } from './authSlice';

export const fetchNumberOfRoofs = createAsyncThunk('landingpage', async () => {
    try {
        const response = await api.fetchNumberOfRoofs();
        return response.data;
    } catch (error) {
        console.error("API Error:", error);
        throw error;
    }
});

const initialStatus = {
    isIdle: true,
    isLoading: false,
    isSuccess: false,
    isError: false,
    error: ''
};

const initialState = {
    data: [],
    status: initialStatus
};

export const landingpageSlice = createSlice({
    name: 'landingpage',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
          .addCase(fetchNumberOfRoofs.pending, (state) => {
            state.status = {...initialStatus, isLoading: true};
          })
          .addCase(fetchNumberOfRoofs.fulfilled, (state, action) => {
            state.status = {...initialStatus, isSuccess: true};
            state.data = action.payload;
          })
          .addCase(fetchNumberOfRoofs.rejected, (state, action) => {
            console.log(action.error.message)
            state.status = {...initialStatus, isError: true, error: action.error.message};
          })
    }
});

export default landingpageSlice.reducer;
export const selectNumberOfRoofs = state => state.landingpage.data;
