import React from 'react'
import { Tooltip } from 'react-tooltip';
import ventilation1 from '../assets/images/ventilation_1.png';
import ventilation2 from '../assets/images/ventilation_2.png';
import ventilation3 from '../assets/images/ventilation_3.png';
import cooling1 from '../assets/images/cooling_1.png';
import cooling2 from '../assets/images/cooling_2.png';
import cooling3 from '../assets/images/cooling_3.png';
import { ReactComponent as InfoIcon } from '../assets/icons/info.svg';
import { useTranslation } from 'react-i18next';


const VentilationTooltip = () => {

    const { t } = useTranslation();

    const data = {
        ventilation: {
            description: t('info.ventilation'),
            images: [
                {
                    label: '',
                    src: ventilation1,
                },
                {
                    label: '',
                    src: ventilation2,
                },
                {
                    label: '',
                    src: ventilation3,
                },
            ]
        },
        cooling: {
            description: t('info.cooling'),
            images: [
                {
                    label: '',
                    src: cooling1,
                },
                {
                    label: '',
                    src: cooling2,
                },
                {
                    label: '',
                    src: cooling3,
                },
            ]
        },
    }
    
    return (
        <>
            <InfoIcon id='ventilation-info' fill='#aaa' data-tooltip-content="images" />
            <Tooltip className='tooltip' anchorId='ventilation-info' place='top' >
                <span className='description'>{t('Ventilation')}: {data.ventilation.description}</span>
                <div className='images'>
                    {data.ventilation.images.map((img) => (
                        <div key={img.src} className='container'>
                            <img src={img.src} alt='ventilation img' height={100} width={100} />
                            <span>{img.label}</span>
                        </div>
                    ))}
                </div>
                <span className='description'>{t('Cooling')}: {data.cooling.description}</span>
                <div className='images'>
                    {data.cooling.images.map((img) => (
                        <div key={img.src} className='container'>
                            <img src={img.src} alt='cooling img' height={100} width={100} />
                            <span>{img.label}</span>
                        </div>
                    ))}
                </div>
            </Tooltip>
        </>
    )
}

export default VentilationTooltip
