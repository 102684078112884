import React from 'react'
import { Tooltip } from 'react-tooltip';
import { ReactComponent as InfoIcon } from '../assets/icons/info.svg';
import oldImg from '../assets/images/old.jpg';
import mediumImg from '../assets/images/medium.jpg';
import newImg from '../assets/images/new.jpg';
import 'react-tooltip/dist/react-tooltip.css'
import { useTranslation } from 'react-i18next';


const WearTooltip = () => {
    const { t } = useTranslation();

    const wears = [
        {
            label: t('1 (new)'),
            src: newImg,
        },
        {
            label: t('2 (medium)'),
            src: mediumImg,
        },
        {
            label: t('3 (old)'),
            src: oldImg,
        },
    ]

    return (
        <>
            <InfoIcon fill='#aaa' id='wear-info' data-tooltip-content="images"/>
            <Tooltip anchorId='wear-info' className='tooltip' place='top'>
                <div className='images'>
                    {wears.map((wear) => (
                        <div key={wear.label} className='container'>
                            <img src={wear.src} alt='wear img' height={100} width={100} />
                            <span>{wear.label}</span>
                        </div>
                    ))}
                </div>
            </Tooltip>
        </>
    )
}

export default WearTooltip
