import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import api from "../../api";
import { toast } from "react-toastify";

export const getSession = (getState) => ({ sessionId: getState().auth.user.sessionId })

export const login = createAsyncThunk('/login', async (token, { rejectWithValue }) => {
    const resp = await api.login(token);
    if(resp.status === 204 || resp.code === 'ERR_NETWORK'){
        return rejectWithValue('No valid user');
    }
   return resp.data;    
});

const storedUser = localStorage?.getItem('user');

const initialState = {
    user: storedUser ? JSON.parse(storedUser) : null,
    sessionId: null,
    isLoading: false,
    isError: false,
    error: '', 
    isLoggedIn: storedUser ? true : false,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
      signOut: (state) => {
        api.logout(state.user);
        state.user = null;
        state.isLoggedIn = false;
        localStorage.removeItem('user');
      }
    },
    extraReducers: (builder) => {
        builder.addCase(login.pending, (state) => {
            state.isLoading = true;
            state.user = null;
            localStorage.removeItem('user');
          })
          .addCase(login.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isLoggedIn = true;
            state.user = action.payload;
            state.sessionId = state.user.sessionId;
            localStorage.setItem('user', JSON.stringify(action.payload));
          })
          .addCase(login.rejected, (state, action) => {
            state.isLoading = false;
            state.user = null;
            state.isError = true;
            state.error = action.payload;
            toast.error(action.payload);
            localStorage.removeItem('user');
          })
    }
});

export const { setCredentials, signOut } = authSlice.actions;

export default authSlice.reducer;
