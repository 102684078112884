
export const rasterLayers = [
	{
		id: "gothenburg_temperature",
		mapboxId: "doinggood.c4xsvydq",
	},
	{
		id: "gothenburg_albedo",
		mapboxId: "doinggood.c38gc6rz",
	},
	{
		id: "gothenburg_landcover",
		mapboxId: "doinggood.cbllrmf0",
	},
	{
		id: "gothenburg_microclimatic",
		mapboxId: "doinggood.c4ukycue",
	},
	{
		id: "chott_temperature",
		mapboxId: "doinggood.4rje33bu"
	},
	{
		id: "chott_albedo",
		mapboxId: "doinggood.3ruizre8"
	},
	{
		id: "chott_landcover",
		mapboxId: "doinggood.3qdqv8mn"
	},
	{
		id: "chott_microclimatic",
		mapboxId: "doinggood.4sdzslab"
	},
];

