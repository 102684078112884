import React from 'react'
import Modal from 'react-modal';
import { contentstyle, overlayStyle } from './config';
import { useTranslation } from 'react-i18next';
import '../../css/modal.css';

const FormModal = (props) => {
    
    const { children, title = '', onCancel, onSubmit, ...modalProps } = props;
    const { t } = useTranslation();

    return (
        <Modal 
            style={{ 
                content: modalProps.contentstyle ? modalProps.contentstyle : contentstyle, 
                overlay:  modalProps.overlayStyle ? modalProps.overlayStyle : overlayStyle, 
            }} 
            {...modalProps}
        >
            <form onSubmit={onSubmit}>
                <section className='modal-header'>
                    <h3>{title}</h3>
                </section>
                <section className='modal-body'>
                    {children}
                </section>
                <section className='modal-footer'>
                    <button type='reset' onClick={onCancel}>{t('table_buttons.cancel')}</button>
                    <button type='submit'>{t('table_buttons.confirm')}</button>
                </section>
            </form>
        </Modal>
    )
}

export default FormModal