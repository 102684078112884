import axios from "axios";

//temp
// export const BASE_URL = "http://localhost:5000"
export const BASE_URL = 'https://api.coolcolor.in';

const api = (() => {

    const ROOF_URL = BASE_URL + '/roofs';
    const DASHBOARD_URL = BASE_URL + '/dashboard';
    const USERS_URL = BASE_URL + '/users';
    const LOGIN_URL = BASE_URL + '/login';
    const OWNERS_URL = BASE_URL + '/owners';
    const LOGOUT_URL = BASE_URL + '/logout';
    const NUMBER_OF_ROOFS_URL = BASE_URL + '/number_of_roofs';
    
    const printError = (error) => {
        if (axios.isAxiosError(error)) {
            console.log(error);
          } else {
            console.log('Unexpected error', error);
          }
    }
    const ping = async () => {
        try {
            const { status } = await axios.get(BASE_URL);
            return status;
          } catch (error) {
            printError(error);
          }
    };
    const isAvailable = async () => {
        return await ping() === 200; 
    };
    const fetchRoofs = async (sessionId) => {
        try {
            const { data } = await axios.post(ROOF_URL, {sessionId});
            return data;
          } catch (error) {
            return error;
          }
    };
    const fetchUsers = async (sessionId) => {
      try {
          const { data } = await axios.post(USERS_URL, {sessionId});
          return data;
        } catch (error) {
          return error;
        }
    };
    const addRoof = async (formData) => {
        try {
            const roof_data = await axios.post(`${ROOF_URL}/add`, formData);
            return roof_data;
          } catch (error) {
            return error;
          }
    };

    const deleteRoof = async (roofID, sessionId) => {
      try {
          const resp = await axios.delete(`${ROOF_URL}/delete/${roofID}`, {data: {sessionId: sessionId}});
          return resp;
        } catch (error) {
          return error;
        }
    };

    const updateRoof = async (roofID, formData) => {
      try {
          const updated_data = await axios.post(`${ROOF_URL}/${roofID}/update`, formData);
          return updated_data;
        } catch (error) {
          return error;
        }
    };
    const updateOwner = async (roof) => {
      try {
          const data = await axios.post(`${OWNERS_URL}/add`, roof);
          return data;
        } catch (error) {
          return error;
        }
    };

    const updateUser = async (data) => {
      try {
          const changedUser = data["data"]["changedUser"]
          const user = data["data"]["user_info"]
          const updated_data = await axios.post(`${BASE_URL}/users/update`, {data: {changedUser: changedUser, user_info: user}, sessionId: user['sessionId']});
          return updated_data;
        } catch (error) {
          return error;
        }
    };

    const flagRoof = async (data) => {
      try {
          const roof = data["data"]["roof"]
          const user = data["data"]["user_info"]
          const flagged_data = await axios.post(`${ROOF_URL}/flag/${roof.roofID}`, user);
          return flagged_data;
        } catch (error) {
          return error;
        }
    };

    const fetchDashboardData = async (user_info) => {
      try {
          const data = await axios.post(DASHBOARD_URL, user_info);
          return data;
        } catch (error) {
          return error;
        }
    };

    const deFlagRoof = async (data) => {
      try {
          const roof = data["data"]["roof"]
          const user = data["data"]["user_info"]
          const flagged_data = await axios.post(`${ROOF_URL}/deflag/${roof.roofID}`, user);
          return flagged_data;
        } catch (error) {
          return error;
        }
    };

    const login = async (token) => {
      try {
          const data = await axios.post(LOGIN_URL, token, {
            headers: {
              'Content-Type': 'application/json'
            }
          });
          return data;
        } catch (error) {
            return error;
        }
    };
    const logout = async (user) => {
      try {
          const data = await axios.post(LOGOUT_URL, {"userID": user['userID'], "sessionId": user['sessionId']})
          return data
      } catch (error) {
        console.log(error);
      }
    };
    
    const addUser = async (data) => {
      try {
        const resp = await axios.post(`${USERS_URL}/add`, {data:{"new_user":data["new_user"], "user_info":data["user_info"]}, "sessionId": data["user_info"]["sessionId"]});
        return resp;
      } catch (error) {
          return error;
      }
    }

    const deleteUser = async (data) => {
      try {
          const userID = data["data"]["userID"]
          const user_info = data["data"]["user_info"]
          const resp = await axios.delete(`${BASE_URL}/users/delete/${userID}`, {data:{"userChanged":userID, "user_info":user_info, "sessionId": user_info['sessionId']}});
          return resp;
        } catch (error) {
          return error;
        }
    };

    const fetchNumberOfRoofs = async () => {
      try {
          const data  = axios.get(NUMBER_OF_ROOFS_URL);
          return data;
        } catch (error) {
          return error;
        }
  };

    return {
        isAvailable,
        fetchRoofs,
        fetchUsers,
        addRoof,
        login,
        logout,
        updateRoof,
        flagRoof,
        deFlagRoof,
        deleteRoof,
        fetchDashboardData,
        addUser,
        deleteUser,
        updateOwner,
        updateUser,
        fetchNumberOfRoofs
    };
  })();
 
export default api;
