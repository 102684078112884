import React, { useMemo, Suspense } from 'react'
import leftIcon from '../../assets/icons/left.svg';
import rightIcon from '../../assets/icons/expand.svg';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

const options = [
    {value: 25, label: 25},
    {value: 50, label: 50},
    {value: 100, label: 100},
]

const styles = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      minHeight: '25px',
      height: '25px',
      fontSize: '.8rem',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '25px',
      padding: '0 6px',
    }),
    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: '.8rem',
      overflow: 'visible'
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '25px',
    }),
};
const TablePagination = (props) => {

    const { previousPage, nextPage, canPreviousPage, canNextPage, pageIndex, pageSize, rows, setPageSize } = props;
    const { t } = useTranslation();
    
    const [start, end] = useMemo(() => {
        let start, end;
        pageIndex > 0 ? start = pageIndex * pageSize + 1 : start = 1;
        canNextPage ? end = (pageIndex + 1) * pageSize : end = rows.length;        
        return [start, end];   
    }, [pageIndex, pageSize, rows, canNextPage])

    return (
        <Suspense fallback="...is loading">
          <div className='table-pagination'>
              <Select
                  defaultValue={options[0]}
                  options={options}
                  onChange={(option) => setPageSize(option?.value)}
                  styles={styles}
                  isSearchable={false}
                  menuPlacement='top'
              />
              <span>{start} - {end} {t('pagination.of')}  {rows.length}</span>
              <div className='buttons'>
                  <button disabled={!canPreviousPage} onClick={() => previousPage()} className='back'>
                      <img alt='pagination-icon' src={leftIcon}/>
                  </button>
                  <button disabled={!canNextPage} onClick={() => nextPage()} className='forward'>
                      <img alt='pagination-icon' src={rightIcon}/>
                  </button>
              </div>
          </div>
        </Suspense>
    )
}

export default TablePagination
