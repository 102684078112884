import React from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../../api";
import { contentstyle, overlayStyle } from "../config";
import "./ViewRoofImageModal.css";

const ViewRoofImageModal = ({ open, onClose, roof }) => {
	const sessionId = useSelector((state) => state.auth.user.sessionId);
	if (!roof.has_image) {
		return null;
	}

	if (!sessionId) {
		return null;
	}

	return (
		<Modal
			isOpen={open}
			onRequestClose={onClose}
			shouldCloseOnEsc={true}
			shouldCloseOnOverlayClick={true}
			style={{
				content: contentstyle,
				overlay: overlayStyle,
			}}
		>
            
			<img
                className="view-roof-image"
				src={`${BASE_URL}/roofs/${roof.roofID}/image?sessionId=${sessionId}`}
				alt="roof"
			/>
		</Modal>
	);
};

export default ViewRoofImageModal;
