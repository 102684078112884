import React from 'react'
import { Tooltip } from 'react-tooltip';
import { ReactComponent as InfoIcon } from '../assets/icons/info.svg';
import roof_type_Concrete from '../assets/images/roof_type_Concrete.jpg';
import roof_type_RoofingFelt from '../assets/images/roof_type_RoofingFelt.jpg';
import roof_type_Rubber from '../assets/images/roof_type_Rubber.jpg';
import roof_type_SheetMetal from '../assets/images/roof_type_SheetMetal.jpg';
import 'react-tooltip/dist/react-tooltip.css'
import { useTranslation } from 'react-i18next';


const RoofTypeTooltip = () => {
    const { t } = useTranslation();

    const roofTypes = [
        {
            label: t('sheetmetal'),
            src: roof_type_SheetMetal,
        },
        {
            label: t('roofingfelt'),
            src: roof_type_RoofingFelt,
        },
        {
            label: t('concrete'),
            src: roof_type_Concrete,
        },
        {
            label: t('rubber'),
            src: roof_type_Rubber,
        },
    ]

    return (
        <>
            <InfoIcon fill='#aaa' id='rooftype-info' data-tooltip-content="images"/>
            <Tooltip anchorId='rooftype-info' className='tooltip' place='top'>
                <div className='images'>
                    {roofTypes.map((roofType) => (
                        <div key={roofType.label} className='container'>
                            <img src={roofType.src} alt='roofType img' height={100} width={100} />
                            <span>{roofType.label}</span>
                        </div>
                    ))}
                </div>
            </Tooltip>
        </>
    )
}

export default RoofTypeTooltip
