import React, { useMemo, useEffect, useState } from 'react'
import { useTable, useFilters, useGlobalFilter, usePagination, useFlexLayout, useExpanded } from 'react-table';
import GlobalSearch from '../table/GlobalSearch';
import TablePagination from '../table/TablePagination';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ExportExcel from '../ExcelExport';
import '../../css/table.css';
import filterIcon from  '../../assets/icons/filter.svg';

const AdminTable = (props) => {
    const [data, setData] = useState(() => props.data);
    const columns = useMemo(() => props.columns, [props.columns]);
    const [ isFilterOpen, setIsFilterOpen ] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (props.data) {
            setData(props.data);
        }
    }, [props.data]);

    
    const defaultColumn = useMemo(() => ({
        Filter: () => undefined,
        width: 150,
        minWidth: 20,
        maxWidth: 250,
    }), []); 

    const dispatch = useDispatch();

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        rows,
        prepareRow,
        state: { expanded, pageSize, pageIndex, ...state },
        preGlobalFilteredRows,
        setGlobalFilter,
        visibleColumns,
        ...paginationProps
    } = useTable({
        columns,
        data,
        defaultColumn,
        initialState: { pageIndex: 0, pageSize: 25},
    }, useFilters, useGlobalFilter, useFlexLayout, useExpanded, usePagination);

    return (
        <>
            <div className='table-menu'>
                <GlobalSearch
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                />
                <div className='buttons'>
                    <button className='filter-btn' onClick={() => setIsFilterOpen(!isFilterOpen)}>
                        <img src={filterIcon} alt='filter icon'/>
                        <span>{t("filter.filter")}</span>
                    </button>
                    <ExportExcel excelData={data} fileName={"Dashboard Data"} />
                </div>
            </div>
            <div className={`filters-section ${isFilterOpen ? 'expanded' : ''}`}  >
                {headerGroups.map(headerGroup => (
                    headerGroup.headers.map((column, index) => (
                        <React.Fragment key={index}>
                            {column.canFilter && (
                                <div key={column.id} className='filter-container'>
                                    <span>{t(column.Header)}</span>
                                    {column.render('Filter')}
                                </div>
                            )}
                        </React.Fragment>
                    ))
                ))}
            </div>
            <table {...getTableProps()}>
                <thead >
                    {headerGroups.map(headerGroup => (
                        <tr  key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th key={column.id} {...column.getHeaderProps()} >
                                    {t(column.render('Header'))}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row)
                        row.getRowProps()
                        return (
                            <React.Fragment key={row.id} >
                                <tr>
                                    {row.cells.map(cell => {
                                        return (
                                            <td className={row.isExpanded ? 'expanded' : ''} key={cell.id} {...cell.getCellProps({
                                                style: {
                                                    minWidth: cell.column.minWidth,
                                                    width: cell.column.width,
                                                  },
                                            })}>
                                                {cell.render('Cell')}
                                            </td>
                                        )
                                    }, [])}
                                </tr>
                            </React.Fragment>
                        )
                    })}
                </tbody>
            </table>
            <div className='table-footer'>
                <TablePagination
                    pageIndex={pageIndex}
                    rows={rows}
                    pageSize={pageSize}
                    {...paginationProps}
                />
            </div>
        </>
    )
}

export default AdminTable
