import React, { Suspense } from 'react'
import { useSelector } from 'react-redux'
import RoofTable from '../components/table/Table'
import { getFlaggedRoofs } from '../redux/slices/roofsSlice'
import tableColumns from '../components/table/columns'
import '../css/admin.css'

const FlaggedRoofs = () => {

  const flaggedRoofs = useSelector(getFlaggedRoofs);
  const { isSuccess } = useSelector(state => state.roofs.fetchStatus);
  
  return (
    <Suspense fallback="...is loading">
      {isSuccess && (
          <RoofTable 
            columns={tableColumns}
            data={flaggedRoofs}
          />
      )}
    </Suspense>
  )
}

export default FlaggedRoofs
