import { Popup } from "mapbox-gl";
import { BASE_URL } from "../../api";

export const ROOF_LAYER_ID = "roofs";

export const addRoofPoints = (map, t) => {

	map.on('load', () => {
		map.addSource("roofs", {
			type: "geojson",
			data: BASE_URL + "/geoJSON/data",
			cluster: true, // Enable clustering
			clusterMaxZoom: 10, // Maximum zoom level for clustering
			clusterRadius: 50, // Cluster radius in pixels
		});
	
		map.addLayer({
			id: "clusters",
			type: "circle",
			source: "roofs",
			filter: ["has", "point_count"],
			paint: {
				"circle-color": "#4c93e6",
				"circle-radius": [
					"step",
					["get", "point_count"],
					20,
					100,
					30,
					750,
					40,
				],
			},
		});
	
		map.addLayer({
			id: "cluster-count",
			type: "symbol",
			source: "roofs",
			filter: ["has", "point_count"],
			layout: {
				"text-field": "{point_count_abbreviated}",
				"text-size": 12,
			},
			paint: {
				"text-color": "darkblue",
			},
		});
	
		map.addLayer({
			id: "unclustered-points",
			type: "circle",
			source: "roofs",
			filter: ["!", ["has", "point_count"]],
			paint: {
				"circle-color": "#4c93e6",
				"circle-radius": 4,
				"circle-stroke-width": 2,
				"circle-stroke-color": "white",
			},
		});
	})
	

	map.on("click", "unclustered-points", (e) => {
		map.getCanvas().style.cursor = "pointer";

		var properties = e.features[0].properties;

		var popupContent = `
        <div class="popup-container">

          <div class="icon-list">
              <h3> ${properties.company}</h3>
              <div>
              <p style="color: #1e73be"><i class="fas fa-map-marker-alt" style="color: #1e73be"></i>  ${
					properties.address
				}, ${properties.city}</p>
              <p style="color: #1e73be"><i class="fa-solid fa-layer-group" style="color: #1e73be"></i> <b>${t(
					"Material"
				)}: </b> ${properties.roofType}</p>
              <p style="color: #1e73be"><i class="fa-solid fa-screwdriver-wrench" style="color: #1e73be"></i>  <b>${t(
					"Wear"
				)}: </b> ${properties.wear}</p>
              <p style="color: #1e73be"><i class="fa-solid fa-maximize" style="color: #1e73be"></i> <b>${t(
					"Perimeter"
				)}: </b> ${properties.circumference} m</p>
              <p style="color: #1e73be"><i class="fa-solid fa-arrow-rotate-left" style="color: #1e73be"></i> <b>${t(
					"Area"
				)}: </b> ${properties.area} m<sup>2</sup></p>
              </div>
          </div>

          <div class="suitable-list">
          <h3>${t("Suitable")}</h3>
            <div>
              <p style="color: #1e73be"><i style="color: green" class="fa-solid fa-check"></i> ${t(
					"techniques.cool"
				)}</p>
              <p style="color: #1e73be"><i style="color: green" class="fa-solid fa-check"></i> ${t(
					"techniques.panels"
				)} </p>
              <p style="color: #1e73be"><i style="color: green" class="fa-solid fa-check"></i> ${t(
					"techniques.battery"
				)} </p>
              <p style="color: #1e73be"><i style="color: #1e73be" class="fa-solid fa-question"></i> ${t(
					"techniques.blue"
				)} </p>
              <p style="color: #1e73be"><i style="color: #1e73be"class="fa-solid fa-question"></i> ${t(
					"techniques.green"
				)} </p>
              
            </div>
          </div>
        </div>
        `;

		new Popup({
			closeButton: false,
			className: "mapbox-popup",
		})
			.setLngLat(e.lngLat)
			.setHTML(popupContent)
			.addTo(map)
			.setMaxWidth("360px");
	});

	map.on("click", "clusters", (e) => {
		const features = map.queryRenderedFeatures(e.point, {
			layers: ["clusters"],
		});

		if (features.length) {
			const clusterId = features[0].properties.cluster_id;
			map.getSource("roofs").getClusterExpansionZoom(
				clusterId,
				(err, zoom) => {
					if (err) return;

					map.easeTo({
						center: features[0].geometry.coordinates,
						zoom: zoom,
					});
				}
			);
		}
	});
};

