import React from 'react'
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import exportIcon from '../assets/icons/export-icon.svg';
import { useTranslation } from 'react-i18next';
import '../css/header.css'

const ExportExcel = ({excelData, fileName}) => {
    const filetype = 'application/vnd.openxmlformats-officedocument.speadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const { t } = useTranslation();
    const exportToExcel = async () => {
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = {Sheets: {'Roof Data': ws}, SheetNames:['Roof Data']};
        const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
        const data = new Blob([excelBuffer], {type:filetype});
        FileSaver.saveAs(data, fileName + fileExtension);
    }
    return (
        <>
        <button className="export-button" onClick={(e) => exportToExcel(fileName)}>
            <img src={exportIcon} alt="export icon" />
            <span>{t('header.export')}</span>
        </button>
        </>
    )
}

export default ExportExcel