import React, { Suspense } from 'react'
import RoofTable from '../components/table/Table';
import { useSelector } from 'react-redux';
import { getRoofs } from '../redux/slices/roofsSlice';
import tableColumns from '../components/table/columns';
import '../css/roofs.css';

const Roofs = () => {

  const roofs = useSelector(getRoofs);  
  
  return (
      <Suspense fallback="...is loading">
          <RoofTable
            data={roofs}
            columns={tableColumns}
          />
      </Suspense>
  )
}

export default Roofs;
