import React from 'react'
import { useDispatch } from 'react-redux';
import FormModal from './FormModal';
import { deleteRoof, fetchRoofs } from '../../redux/slices/roofsSlice';
import { confirmationStyle } from './config';
import { useTranslation } from 'react-i18next';
import '../../css/addRoofForm.css';

const DeleteConfirmationModal = ({ open, onClose, roof }) => {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    

    const handleOnSubmit = async (event) => {
        event.preventDefault();
        try {
            await dispatch(deleteRoof(roof.roofID)).unwrap();
            await dispatch(fetchRoofs());
            onClose();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <FormModal
            id='delete'
            isOpen={open}
            onCancel={() => onClose()}
            onSubmit={handleOnSubmit}
            contentstyle={confirmationStyle}
        >
            <h4>{t('confirmation.delete')}</h4>
        </FormModal>
    )
}

export default DeleteConfirmationModal