import React from 'react'
import expandIcon from '../../assets/icons/expand.svg';

const ExpandCell = ({ row }) => {
  return (
    <span {...row.getToggleRowExpandedProps()} className='expand-btn-container' >
        <img 
          className={`expand-btn ${row.isExpanded ? 'expanded' : ''}`} 
          src={expandIcon} 
          alt='expand' />
    </span>
  )
}

export default ExpandCell
