import mapboxgl, { Map } from "mapbox-gl";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { rasterLayers } from "./MapBoxLayers";
import { addRoofPoints } from "./MapBoxRoofPoints";
import "./MapBox.css";

mapboxgl.accessToken =
	"pk.eyJ1IjoiZG9pbmdnb29kIiwiYSI6ImNsbzVydHQ1eDBkcDQyaW8zZTYwejJwZnYifQ.UjvBD_0wlYoFzAts7w9zZA";

const createMap = (ref, t) => {
	const map = new Map({
		container: ref.current,
		style: "mapbox://styles/mapbox/satellite-streets-v12",
		center: [28.0686, 39.3293], // Initial center of the map

		//zoom: 2.2, // Initial zoom level
		maxTileCacheSize: 512,
	});

	if (window.matchMedia("(min-width: 420px)").matches) {
		map.setZoom(2); //set map zoom level for desktop size
	} else {
		map.setZoom(1.1); //set map zoom level for mobile size
	}

	map.jumpTo({ center: [11.97456, 57.70887], zoom: 10 });

	map.on("style.load", () => {
		map.setFog({});
	});

	map.on("load", () => {
		rasterLayers.forEach((source) => {
			map.addSource(source.id, {
				url: "mapbox://" + source.mapboxId,
				type: "raster",
			});
			map.addLayer({
				// The toggleable layer id
				id: source.id,
				type: "raster",
				source: source.id,
				layout: {
					// Make the layer invisible by default.
					visibility: 'none'
				}
			});
		});
	});

	addRoofPoints(map, t);

	map.on("mouseenter", "clusters", () => {
		map.getCanvas().style.cursor = "pointer";
	});

	map.on("mouseleave", "clusters", () => {
		map.getCanvas().style.cursor = "";
	});

	return map;
};

const setLayerVisibility = (map, layers, visible) => {
	const visibility = visible ? 'visible' : 'none'
	layers.forEach(layer => {
		map.setLayoutProperty(layer, 'visibility', visibility)
	})
};

const getLayerGroups = (t) => [
	{
		name: "Temperatur",
		id: "temperature",
		layers: ["gothenburg_temperature", "chott_temperature"]
	},
	{
		name: "Albedo",
		id: "albedo",
		layers: ["gothenburg_albedo", "chott_albedo"]
	},
	{
		name: "Landfyllnad",
		id: "landcover",
		layers: ["gothenburg_landcover", "chott_landcover"]
	},
	{
		name: "Mikroklimat",
		id: "microclimatic",
		layers: ["gothenburg_microclimatic", "chott_microclimatic"]
	}
]

const getRoofGroup = (t) => ({
	name: 'Takpunkter',
	layers: ["clusters", "cluster-count", "unclustered-points"]
})

const MapBox = () => {
	const containerRef = useRef(null);
	const mapRef = useRef(null);
	const { t } = useTranslation();
	const layerGroups = getLayerGroups(t);
	const roofGroup = getRoofGroup(t);

	// No layers visible from the start
	const [visibleLayerGroup, setVisibleLayerGroup] = useState(undefined);
	const [isRoofsVisible, setRoofsVisible] = useState(true);
	const [visibilityFunc, setVisibilityFunc] = useState(() => () => {})

	useEffect(() => {
		if (!containerRef.current) return;
		const map = createMap(containerRef, t);
		mapRef.current = map;
		map.on('ready', setVisibilityFunc(() => setLayerVisibility))
		return () => {
			map.remove();
			mapRef.current = null;
		};
	}, [containerRef, t]);

	useEffect(() => {
		const map = mapRef.current;
		if (!map) return;

		try {
			layerGroups.forEach(group => {
				visibilityFunc(map, group.layers, visibleLayerGroup === group.id)
			})
		} catch (e) {
			console.log(e);
		}

	}, [mapRef, layerGroups, visibleLayerGroup, t, visibilityFunc]);

	useEffect(() => {
		const map = mapRef.current;
		if (!map) return;
		try {
			visibilityFunc(map, roofGroup.layers, isRoofsVisible)
		} catch (e) {
			console.log(e);
		}
	}, [mapRef, isRoofsVisible, roofGroup, visibilityFunc]);

	return (
		<div className="roof-map-container">
			<div className="roof-map" ref={containerRef} />

			<div>
				{layerGroups.map(layerGroup => {
					const isActive = visibleLayerGroup === layerGroup.id
					return <button 
							className={'layer-toggle-button' + (isActive ? ' layer-active' : '')} 
							onClick={() => setVisibleLayerGroup(isActive ? undefined : layerGroup.id)}>
						{layerGroup.name}
					</button>
				})}

				<button 
					className={'layer-toggle-button' + (isRoofsVisible ? ' layer-active' : '')} 
					onClick={() => setRoofsVisible(!isRoofsVisible)}>
						{roofGroup.name}
				</button>
			</div>
			
		</div>
	);
};

export default MapBox;
