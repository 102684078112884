import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRoutes = () => {

    const { user } = useSelector(state => state.auth);

    if(user && Boolean(user?.user_is_admin)){
      return <Navigate to='/admin' replace />;
    }
    if (user && !Boolean(user?.user_is_admin)) {
      return <Navigate to='/roofs' replace />;
    }
    return <Outlet />;
};
export default ProtectedRoutes;