import React from 'react'
import { GoogleLogin } from '@react-oauth/google';
import { useDispatch } from 'react-redux';
import { login } from '../redux/slices/authSlice';
import { useTranslation } from 'react-i18next';
import '../css/login.css';

const Login = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleOnSuccess = async (credentialResponse) => {
        try {
            await dispatch(login(credentialResponse)).unwrap();
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className='login-container'>
            <h1>{t('login.login')}</h1>
                <GoogleLogin 
                    onSuccess={handleOnSuccess}
                    onError={(res) =>  console.log('failure: ', res)}
                />
            <span className='info'>{t('login.info')}</span>
        </div>
    )
}

export default Login
