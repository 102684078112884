import React, { useMemo } from 'react'
import logo from '../assets/images/logo.png';
import { NavLink, Link } from 'react-router-dom';
import DropdownMenu from './DropdownMenu';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import '../css/header.css';

const Header = () => {
    const { t } = useTranslation();
    const { user } = useSelector(state => state.auth);
    
    const homeDestination = useMemo(() => {
        if(user && Boolean(user?.user_is_admin)){
            return '/admin';
        }
        else if(user && !Boolean(user?.user_is_admin)) {
            return '/roofs';
        }
        return '/map';
    }, [user])

    return (
        <header>
            <nav>
                <Link to={homeDestination}>
                    <img
                        src={logo}
                        className='logo'
                        alt='Global Coolify på logo'
                    />
                </Link>
                <li>
                    <NavLink to={homeDestination}>{t('header.home')}</NavLink>
                </li>
                {user && Boolean(user?.user_is_admin) && (
                    <>
                        <li>
                            <NavLink to='/roofs'>{t('header.roofs')}</NavLink>
                        </li>
                        <li>
                            <NavLink to='/map'>{t('header.map')}</NavLink>
                        </li>
                    </>
                )}
                {user && !Boolean(user?.user_is_admin) && (       
                    <li>
                        <NavLink to='/map'>{t('header.map')}</NavLink>
                    </li>
                )}
            </nav>
            <div className='right-section'>
                <DropdownMenu />
            </div>
        </header>
    )
}

export default Header
