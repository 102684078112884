import React, { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AddUserModal from '../components/modal/AddUserModal';
import userColumns from '../components/userTable/userColumns';
import UserTable from '../components/userTable/userTable';
import '../css/admin.css';

const Users = () => {

    const { data } = useSelector(state => state.users);
    const [ isModalOpen, setIsModalOpen ] = useState(false);
    const { t } = useTranslation();

    return (
      <Suspense fallback="...is loading">
        <div className='menu-row'>
          <AddUserModal
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          />
          <button className ='open-modal-btn blue-button' onClick={() => setIsModalOpen(true)}>{t('table_buttons.add_user')}</button>
        </div>
          <UserTable
            data={data}
            columns={userColumns}
          />
      </Suspense>
    )
}

export default Users
