import React, { Suspense } from 'react'
import { useSelector } from 'react-redux'
import RoofTable from '../components/table/Table'
import { getRoofsByUserId } from '../redux/slices/roofsSlice'
import tableColumns from '../components/table/columns'

const MyRoofs = () => {

    const { user } = useSelector(state => state.auth);
    const roofs = useSelector(getRoofsByUserId(user?.userID));
    const { isSuccess } = useSelector(state => state.roofs.fetchStatus);

    return (
        <Suspense fallback="...is loading">
            {isSuccess && (
                <RoofTable
                    data={roofs}
                    columns={tableColumns}
                />
            )}
        </Suspense>
    )
}

export default MyRoofs
