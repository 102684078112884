import React, { useEffect } from 'react'
import Header from './components/Header';
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import AdminDashboard from './pages/AdminDashboard';
import Login from './pages/Login';
import ProtectedRoutes from './pages/protectedRoutes';
import AdminOutlet from './pages/AdminOutlet';
import Users from './pages/Users';
import FlaggedRoofs from './pages/FlaggedRoofs';
import { useIdleTimer } from 'react-idle-timer'
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from './redux/slices/authSlice';
import RoofsOutlet from './pages/RoofsOutlet';
import MyRoofs from './pages/MyRoofs';
import Roofs from './pages/Roofs';
import { ToastContainer } from 'react-toastify';
import { toastOptions } from './constants';
import Footer from './components/Footer';
import { fetchRoofs } from './redux/slices/roofsSlice';
import { fetchUsers } from './redux/slices/usersSlice';
import MapboxRoofMap from './pages/MapboxRoofMap';

const App = () => {

    const dispatch = useDispatch();
    const { isIdle } = useSelector(state => state.roofs.fetchStatus); 
    const { updateStatus } = useSelector(state => state.users); 
    const { user } = useSelector(state => state.auth); 
    const onIdle = () => {
        dispatch(signOut());
    }

    useIdleTimer({ onIdle, timeout: 1200000 })

    useEffect(() => {
        if(isIdle && user) {
            dispatch(fetchRoofs());
            dispatch(fetchUsers());
        }
    },[dispatch, isIdle, user])

    useEffect(() => {
        if(updateStatus.isSuccess && user) {
            dispatch(fetchUsers());
        }
    },[dispatch, updateStatus, user])
 
    return (
        <Router>
            <Header />
            <ToastContainer { ...toastOptions } />
            <Routes>
                <Route element={<ProtectedRoutes />}>
                    <Route path='/login' element={<Login/>} />
                </Route>
                <Route path='/roofs' element={<RoofsOutlet />}>
                    <Route index element={<Roofs />} />   
                    <Route path=':id' element={<MyRoofs />} />   
                </Route>
                <Route path="/admin" element={<AdminOutlet />}>
                    <Route index element={<AdminDashboard />} />
                    <Route path='users' element={<Users />} />
                    <Route path='flagged' element={<FlaggedRoofs />} />
                </Route>
                <Route path='/map' element={<MapboxRoofMap />} />   
                <Route path='/' element={<MapboxRoofMap />} />               
            </Routes>   
            <Footer/>
        </Router>
    )
}

export default App
