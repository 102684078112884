import OptionFilter from "../table/OptionFilter"
import RangeFilter from "../table/RangeFilter";


const adminColumns = [
    {
        Header: 'User',
        accessor: 'display_name',
        Filter: OptionFilter,
    },
    {
        Header: 'Hours (month)',
        accessor: 'hours_month',
        Filter: RangeFilter
    },
    {
        Header: 'Hours (week)',
        accessor: 'hours_week',
        Filter: RangeFilter
    },
    {
        Header: 'Average hours (month)',
        accessor: 'hours_avg_month',
        Filter: RangeFilter
    },
    {
        Header: 'Roofs (month)',
        accessor: 'roofs_month',
        Filter: RangeFilter
    },
    /*
    {
        Header: 'Roofs (week)',
        accessor: 'roofs_week',
        Filter: RangeFilter
    },*/
    {
        Header: 'Average roofs (month)',
        accessor: 'roofs_avg_month',
        Filter: RangeFilter
    },
    {
        Header: 'Roofs (in total)',
        accessor: 'total_roofs',
        Filter: RangeFilter
    }
];

export default adminColumns;
