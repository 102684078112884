import React, { useEffect, Suspense, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchDashboardData, getDashboardData } from '../redux/slices/dashboardSlice';
import adminColumns from '../components/adminTable/adminColumns';
import AdminTable from '../components/adminTable/AdminTable'
import { useTranslation } from 'react-i18next';
import 'moment/locale/sv';
import 'moment/locale/es';
import 'moment/locale/uk';
import '../css/admin.css';

const Dashboard = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true)
    const data = useSelector(getDashboardData)
    const moment = require('moment')
    moment().format()   
    const date = new Date(); 
    const week = moment(date).isoWeek();

    const { user } = useSelector(state => state.auth); 
  
    if (i18n.language === 'sv'){
        moment.locale('sv')
    } else if (i18n.language === 'es') {
        moment.locale('es')
    } else if (i18n.language === 'uk') {
        moment.locale('uk')
    } else if (i18n.language === 'en') {
        moment.locale('en')
    }

    const month = moment(date).format('MMMM')

    useEffect(() => {
        try {
            dispatch(fetchDashboardData()).then(() => setLoading(false))
        } catch (error) {
          console.log(error);
        }
      },[dispatch])

    return (
        <Suspense fallback="...is loading" >
            {!loading && (
            <>
                <div className='dashboard'>
                    <div className='kpis'>
                        <div className='kpi-container'>
                            <span>{data.at(-1).total_roofs}</span>
                            <span>{t('dashboard.total_roofs')}</span>
                        </div>
                        <div className='kpi-container'>
                            <span>{data.at(-1).roofs_month}</span>
                            <span>{t('dashboard.flagged_month')} {month}</span>
                        </div>
                        <div className='kpi-container'>
                            <span>{data.at(-1).roofs_week}</span>
                            <span>{t('dashboard.flagged_week')} {week}</span>
                        </div>
                        <div className='kpi-container'>
                            <span>{data.at(-1).hours_month}</span>
                            <span>{t('dashboard.time_month')} {month}</span>
                        </div>
                        <div className='kpi-container'>
                            <span>{data.at(-1).hours_week}</span>
                            <span>{t('dashboard.time_week')} {week}</span>
                        </div>
                    </div>
                </div>
                <AdminTable
                    data={data.slice(0, -1)}
                    columns={adminColumns}
                />
            </> 
            )}
        </Suspense>
    )
}

export default Dashboard
