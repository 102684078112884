import React from "react";
import { MdEmail } from "react-icons/md";
import { AiFillHome } from "react-icons/ai";
import { useTranslation } from 'react-i18next';
import '../css/footer.css'

const Footer = () => {

    const { t } = useTranslation();
    return (
        <footer>
            <nav>
                <h3>{t('footer.contact')}</h3>  
                <a href="https://www.globalcoolify.se/"> <AiFillHome/>globalcoolify.se</a>
                <a href="mailto:info@globalcoolify.se"> <MdEmail/>info@globalcoolify.se</a>
            </nav>
        </footer>
    );
};
export default Footer;

